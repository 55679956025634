<template>
  <pie-chart
    title="Répartition CA"
    subTitle="par partenaire"
    :series="series"
    :labels="labels"
    :loading="loading"
    :formatter="formatter"
    :colors="colors"
  />
</template>

<script>
import { cacheTime } from "@axios";

import PieChart from "@/components/common/charts/PieChart.vue";

import useAxios from "@/hooks/useAxios";
import { chartColors } from "@/utils/constants";
import { formatCurrency } from "@/utils/formatting";

export default {
  name: "CADistribution",
  components: { PieChart },
  data() {
    return {
      loading: false,
      series: [],
      labels: [],
      formatter: (val) => formatCurrency(val),
      colors: chartColors, //["#ffe700", "#00d4bd", "#826bf8", "#2b9bf4", "#FFA1A1"],
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.month &&
        this.month.length > 5
      ) {
        const queryParams = {
          month: this.month,
          ...(this.sites && this.sites.length > 0
            ? { sites: this.sites.join(",") }
            : {}),
          ...(this.partnerViews && this.partnerViews.length > 0
            ? { partner_views: this.partnerViews.join(",") }
            : {}),
        };
        this.loading = true;
        const { data: topPartnersData } = await this.axiosGet(
          "/programmatic/best-partners",
          queryParams,
          {
            cache: {
              maxAge: cacheTime,
            },
          }
        );

        this.series = topPartnersData.map((data) => data.ca);
        this.labels = topPartnersData.map((data) => data.name);
        this.loading = false;
      }

      // Force clicking on button again to re-run that request :
      // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
      this.$store.dispatch("common/updateApplyFilters", false);
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters["premium/getSites"];
    },
    month() {
      return this.$store.getters["premium/getMonthDate"];
    },
    partnerViews() {
      return this.$store.getters["premium/getPartnerViews"];
    },
  },
  watch: {
    applyFilters() {
      this.getData();
    },
  },
};
</script>
