var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"align-start"},[_c('span',{staticClass:"font-weight-semibold text-base"},[_vm._v("Statistiques")])]),(_vm.loading)?_c('loader'):_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"elevation-1",attrs:{"size":"44","color":"primary","rounded":""}},[_c('v-icon',{attrs:{"dark":"","color":"white","size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCurrencyEur)+" ")])],1),_c('div',{staticClass:"ms-3"},[_c('p',{staticClass:"text-xs mb-0"},[_vm._v("CA")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--primary text-xl font-weight-bold"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.ca.value))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.ca.raw))])])],1)],1)]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"elevation-1",attrs:{"size":"44","color":"primary","rounded":""}},[_c('v-icon',{attrs:{"dark":"","color":"white","size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTarget)+" ")])],1),_c('div',{staticClass:"ms-3"},[_c('p',{staticClass:"text-xs mb-0"},[_vm._v("Objectif CA")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--primary text-xl font-weight-bold"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.objectif.value))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.objectif.raw))])])],1)],1)]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"elevation-1",attrs:{"size":"44","color":"primary","rounded":""}},[_c('v-icon',{attrs:{"dark":"","color":"white","size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPercent)+" ")])],1),_c('div',{staticClass:"ms-3"},[_c('p',{staticClass:"text-xs mb-0"},[_vm._v("Ratio CA/Objs")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--primary text-xl font-weight-bold"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.ratio.value))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.ratio.raw))])])],1)],1)]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"elevation-1",attrs:{"size":"44","color":"primary","rounded":""}},[_c('v-icon',{attrs:{"dark":"","color":"white","size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiSpeedometer)+" ")])],1),_c('div',{staticClass:"ms-3"},[_c('p',{staticClass:"text-xs mb-0"},[_vm._v("RPM")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--primary text-xl font-weight-bold"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.rpm.value))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.rpm.raw))])])],1)],1)]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"elevation-1",attrs:{"size":"44","color":"primary","rounded":""}},[_c('v-icon',{attrs:{"dark":"","color":"white","size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCashMultiple)+" ")])],1),_c('div',{staticClass:"ms-3"},[_c('p',{staticClass:"text-xs mb-0"},[_vm._v("Estimation CA")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--primary text-xl font-weight-bold"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.estiCa.value))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.estiCa.raw))])])],1)],1)]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"elevation-1",attrs:{"size":"44","color":"primary","rounded":""}},[_c('v-icon',{attrs:{"dark":"","color":"white","size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiSackPercent)+" ")])],1),_c('div',{staticClass:"ms-3"},[_c('p',{staticClass:"text-xs mb-0"},[_vm._v("Ratio CA/Estimations")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--primary text-xl font-weight-bold"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.estiRatio.value))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.estiRatio.raw))])])],1)],1)]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"elevation-1",attrs:{"size":"44","color":"primary","rounded":""}},[_c('v-icon',{attrs:{"dark":"","color":"white","size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccount)+" ")])],1),_c('div',{staticClass:"ms-3"},[_c('p',{staticClass:"text-xs mb-0"},[_vm._v("Sessions")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--primary text-xl font-weight-bold"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.session.value))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.session.raw))])])],1)],1)]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"elevation-1",attrs:{"size":"44","color":"primary","rounded":""}},[_c('v-icon',{attrs:{"dark":"","color":"white","size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTargetAccount)+" ")])],1),_c('div',{staticClass:"ms-3"},[_c('p',{staticClass:"text-xs mb-0"},[_vm._v("Objectif sessions")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--primary text-xl font-weight-bold"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.sessionObjective.value))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.sessionObjective.raw))])])],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }