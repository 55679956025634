<template>
  <div>
    <v-expansion-panels class="mb-2" id="expandableFilters">
      <v-expansion-panel>
        <v-expansion-panel-header> </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="ml-3">
            <v-col cols="6" sm="4" md="3">
              <partner-group-filter store="premium" />
            </v-col>
            <v-col cols="6" sm="4" md="6">
              <partner-view-filter store="premium" />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card class="mb-6 pa-2">
      <v-row class="ma-3">
        <v-col cols="12">
          <v-row class="d-flex">
            <v-col cols="2">
              <date-filter
                store="premium"
                storeGetter="getMonthDate"
                storeUpdater="updateMonthDate"
                format="month"
                :isRange="false"
              />
            </v-col>
            <v-col cols="4">
              <site-group-filter store="premium" />
            </v-col>
            <v-col cols="4">
              <site-filter store="premium" />
            </v-col>

            <v-col cols="2">
              <apply-filters :disabled="isLoading" outlined color="secondary" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import ApplyFilters from "@/components/common/filters/ApplyFilters";
import DateFilter from "@/components/common/filters/DateFilter";
import PartnerGroupFilter from "@/components/common/filters/PartnerViewGroupFilter";
import PartnerViewFilter from "@/components/common/filters/PartnerViewFilter";
import SiteFilter from "@/components/common/filters/SiteFilter";
import SiteGroupFilter from "@/components/common/filters/SiteGroupFilter";

import useAxios from "@/hooks/useAxios";

export default {
  name: "Filters",
  components: {
    ApplyFilters,
    DateFilter,
    PartnerGroupFilter,
    PartnerViewFilter,
    SiteFilter,
    SiteGroupFilter,
  },
  data() {
    return {};
  },
  setup() {
    const { isLoading } = useAxios();

    return {
      isLoading,
    };
  },
  methods: {},
};
</script>

<style>
.v-date-picker-title__date > div {
  font-size: 22px;
}
</style>
