<template>
  <v-card>
    <v-card-title>Sites</v-card-title>
    <loader v-if="loading" />
    <v-card-text v-else>
      <v-data-table :headers="headers" :items="items" :items-per-page="5">
        <template v-slot:item.revenue="{ item }">
          <span>{{ formatNumber(item.revenue) }}</span>
        </template>
        <template v-slot:item.sessions="{ item }">
          <span>{{ formatNumber(item.sessions) }}</span>
        </template>
        <template v-slot:item.rpm="{ item }">
          <span>{{ formatNumber(item.rpm) }}</span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { cacheTime } from "@axios";

import Loader from "@/components/common/Loader";
import useAxios from "@/hooks/useAxios";
import { formatNumber } from "@/utils/formatting";

export default {
  name: "SiteRevenueSessionRpmTable",
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "Site",
          align: "start",
          value: "site",
        },
        {
          text: "Revenue",
          value: "revenue",
        },
        {
          text: "Sessions",
          value: "sessions",
        },
        {
          text: "RPM",
          value: "rpm",
        },
      ],
      items: [],
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    formatNumber,
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.month &&
        this.month.length > 5
      ) {
        const queryParams = {
          month: this.month,
          limit: 100,
          ...(this.sites && this.sites.length > 0
            ? { sites: this.sites.join(",") }
            : {}),
          ...(this.partnerViews && this.partnerViews.length > 0
            ? { partner_views: this.partnerViews.join(",") }
            : {}),
        };

        this.loading = true;
        const { data } = await this.axiosGet(
          "/programmatic/top-rpm-per-site",
          queryParams,
          {
            cacheTime: cacheTime,
          }
        );
        this.items = data.map((item) => {
          return {
            site: item.name,
            revenue: item.ca,
            sessions: item.sessions,
            rpm: item.rpm,
          };
        });
        this.loading = false;
      }

      // Force clicking on button again to re-run that request :
      // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
      this.$store.dispatch("common/updateApplyFilters", false);
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters["premium/getSites"];
    },
    month() {
      return this.$store.getters["premium/getMonthDate"];
    },
    partnerViews() {
      return this.$store.getters["premium/getPartnerViews"];
    },
  },
  watch: {
    applyFilters() {
      this.getData();
    },
  },
};
</script>
